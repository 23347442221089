@media screen and (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

@media screen and (max-width: 1399px) {
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 1024px) {
  * {
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    -moz-border-radius: 0;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .swipeButtons {
    padding-top: 50px;
  }
  .relationship_type_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .its_a_match {
    padding: 0px 25px;
  }
  .relationship_button{
    padding: 30px;
  }
}

@media screen and (max-device-height: 414px) and (orientation: landscape) {
}
@media screen and (max-width: 575px) {
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 320px) {
}
