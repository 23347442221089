.swipeButtons {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center
}

.swipeButtons .MuiIconButton-root {
    background-color: white;
    box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3) !important;
    margin: 20px;
    width: 10px;
    height: 10px;
}


.swipeButtons__left {
    padding: 2vw !important;
}


.swipeButtons__right {
    padding: 2vw !important;
}