.relationship_type_buttons {
    position: relative;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center
}
.its_a_match {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
}

.blurred{
    background-color: #ff6600ad;

}
.relationship_type_buttons .MuiIconButton-root {
    background-color: white;
    box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3) !important;
    margin: 20px;

}


.relationship_button {
    margin: 5px!important;
    padding: 1vw !important;
    color: #fff!important;
    border-radius: 15px!important;
}


.friendship_button {
    margin: 5px!important;
    padding: 1vw !important;
    color: #fff!important;
    border-radius: 15px!important;

}