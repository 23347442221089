.chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 70px;
    border-bottom: 1px #FAFAFA;
}

.chat__details {
    flex: 1;
    text-align: left;
    padding-left: 10px;
}

.chat__details h2 {
    font-weight: 700;
}

.chat__details > p {
    color: gray;
}

.chat__timestamp {
    color: #d1d1d1;
}