.card-container{
    max-width: 27vw;
    background-color: white;
    border-radius: 10px;
}

.card-container .bio-text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 20px;
    min-height: 85px;
    height: 100px;
}
.card {
    position: relative;
    width:500px;
    padding: 20px;
    max-width: 50vw;
    height: 50vh!important;
    border: none!important;
    border-radius: 20px!important;
    background-size: cover;
    background-position: center;
    /* background-color: red; */
    /* box-shadow: 0px 18px 53px 0 rgba(0 ,0 ,0 ,0.3); */
}

.profile_name{
    color: #FF6600;
    font-size: 2rem;
    font-weight: 600;
}
.card > h3 {
    position: absolute;
    bottom: 10px;
    color: white;
}

.profile_details, .profile_bio{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    

}

.profile_bio{
    padding: 5px;
    font-weight: 600;

}
.profile_details{
    padding-top: 30px;
}
.tinderCards__cardContainer {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.loader-container {
    display: none;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: #000000b3;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-container .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #FF6600; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.swipe {
    position: absolute;
}
.swipeButtons {
    padding-top: 80px;
}

.center_div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
  

